<template>
  <div class="searchDetail">
    <main class="main">
      <div class="main_search">
        <el-form
          ref="form"
          :model="searchData"
          label-width="80px"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input
              v-model="searchData.keyWord"
              prefix-icon="el-icon-search"
              @keyup.enter.native.prevent="hadleEnterSearch"
            >
              <el-button slot="append" @click.native="hadleEnterSearch"
                >Search</el-button
              >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox-group v-model="checkOptions" @change="handleCheck">
              <el-checkbox
                v-for="val in optinos"
                :label="val.menuId"
                :key="val.menuId"
                >{{ val.label }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="main_content"
        v-loading="searchLoading"
        v-if="content.length > 0"
      >
        <div
          class="main_content_itme"
          v-for="(item, index) in content"
          :key="index"
        >
          <div class="content_itme_box">
            <div class="box_title">
              <p
                class="box_title_item"
                @click="goDetailPage(item)"
                v-html="item.title"
              ></p>
              <span title="Recommend" v-if="item.recommendLevel == 1"
                ><svg-icon icon-class="hot"></svg-icon
              ></span>
            </div>
            <p class="box_postTime">
              <span>Post&nbsp;</span
              >{{
                item.releaseTime
                  ? moment(item.releaseTime).format("YYYY.MM.DD")
                  : "--"
              }}
            </p>
          </div>
          <div class="content_itme_text">
            <p>
              <span
                @click="goDetailPage(item)"
                class="text"
                v-html="item.content + '...'"
              ></span>
              <span @click="goDetailPage(item)" class="more">More</span>
            </p>
          </div>
          <div class="content_itme_tag">
            <span v-for="val in item.treeList" :key="val.id">{{
              val.name
            }}</span>
          </div>
        </div>
      </div>
      <div class="main_content" v-loading="searchLoading" v-else>
        <div class="iconBox">
          <svg-icon icon-class="nodate"></svg-icon>
          <p class="nodata">No Data</p>
        </div>
      </div>
      <div class="main_Pagination">
        <p class="result"><span>Result of Search: </span><span>{{Pagination.total.toLocaleString()}}</span></p>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="Pagination.pageNumber"
          :page-sizes="[10, 20, 50]"
          :page-size="Pagination.pageSize"
          layout="sizes, prev, pager, next"
          prev-text="Previous"
          next-text="Next"
          :total="Pagination.total"
        >
        </el-pagination>
      </div>
    </main>
  </div>
</template>

<script>
import { searchInfo } from "../../api/common";
import moment from "moment";
export default {
  name: "searchDetail",
  data() {
    return {
      searchLoading: false,
      searchData: {
        keyWord: "",
        moduleId: "",
        pageNumber: 1,
        pageSize: 20,
        sortType: "",
        menuId: "",
      },
      //复选框选中的值
      checkOptions: [],
      //排序
      releasevalue: "",
      releaseTime: [
        {
          label: "Post Date",
          value: "1",
        },
        {
          label: "Recommend",
          value: "2",
        },
      ],
      //复选框的值
      optinos: [],
      content: [],
      Pagination: {
        total: 0,
        pageSize: 20,
        pageNumber: 1,
      },
    };
  },
  methods: {
    //排序
    handleSort(val) {
      this.releasevalue = val;
      this.getSearchData();
    },
    //点击跳转详情页
    goDetailPage(val) {
      let data = {
        articleType: val.articleType,
        articleId: val.articleId,
        keyword: this.searchData.keyWord
      };
      // this.$router.push({
      //   path: "/detailPage",
      //   query: { articleData: JSON.stringify(data) },
      // });
      // window.open(`/detailPage?articleData=${JSON.stringify(data)}`);
      let routeUrl = this.$router.resolve({
          path: "/detailPage",
          query: { articleData: JSON.stringify(data) },
        });
        window.open(routeUrl.href, "_blank");
      },
    //点击搜索
    hadleEnterSearch() {
      this.getSearchData();
    },
    //搜索结果
    getSearchData() {
      this.searchLoading = true;
      let params = this.searchData;
      params.moduleId =
        this.checkOptions.length > 0 ? this.checkOptions.join() : "";
      // params.sortType = this.releasevalue;
      params.pageNumber = this.Pagination.pageNumber;
      params.pageSize = this.Pagination.pageSize;
      searchInfo(params)
        .then((res) => {
          this.content = res.data.rows.rows;
          this.Pagination.total = res.data.rows.total;
          this.searchLoading = false;
        })
        .catch((err) => {
          this.searchLoading = false;
        });
    },
    //分页搜索
    handleCurrentChange(val) {
      this.Pagination.pageNumber = val;
      this.getSearchData();
    },
    //切换每页显示数量
    handleSizeChange(val) {
      this.Pagination.pageSize = val;
      this.getSearchData();
    },
    //点击复选框搜索
    handleCheck(val) {
      this.getSearchData();
    },
  },
  created() {
    let query = this.$route.query;
    this.searchData.keyWord = query.keyWord;
    this.checkOptions = query.type ? query.type.map(Number) : [];
    this.optinos = this.$store.state.menuData.slice(1);
    this.getSearchData();
  },
  computed: {
    moment() {
      return moment;
    },
  },
};
</script>

<style lang='scss' scoped>
.searchDetail {
  width: 100%;
  .header {
    width: 100%;
  }
  .main {
    width: 100%;
    background: #f2f6ff;
    padding: 0 12%;
    box-sizing: border-box;
    .main_search {
      padding: 20px 140px 0px 140px;
      /deep/ .el-form {
        .el-form-item {
          .el-form-item__content {
            .el-checkbox-group {
              margin-left: 41px;
            }
            .el-input-group__append {
              border-radius: 0 30px 30px 0;
            }
            .el-input {
              .el-input__inner {
                border-top-left-radius: 30px;
                border-bottom-left-radius: 30px;
              }
              .el-input-group__append {
                .el-button {
                  border-top-right-radius: 30px;
                  border-bottom-right-radius: 30px;
                }
              }
            }
          }
        }
      }
    }
    .main_title {
      // padding: 0 360px;
      .main_title_item {
        padding: 25px 30px;
        background: #bccbf0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          font-size: 22px;
          color: #2d2f36;
        }
      }
    }
    .main_content {
      width: 100%;
      min-height: 600px;
      .main_content_itme {
        width: 100%;
        background: #fff;
        padding: 15px 21px 5px 21px;
        .content_itme_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .box_title {
            display: flex;
            align-items: center;
            .box_title_item {
              font-size: 20px;
              color: #232949;
              cursor: pointer;
            }
            .svg-icon {
              margin-left: 5px;
              font-size: 24px;
              color: red;
            }
          }
          .box_postTime {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #7a7b80;
          }
        }
        .content_itme_text {
          width: 100%;
          padding: 10px 0 10px 0;
          line-height: 30px;
          p {
            font-size: 14px;
            color: #7a7b80;
            display: flex;
            align-items: flex-end;
            position: relative;
            .text {
              cursor: pointer;
              display: inline-block;
              width: 95%;
              word-break: break-word;
              font-size: 14px;
            }
            .more {
              background: linear-gradient(121deg, #558aee 0%, #355ec4 100%);
              padding: 4px 14px;
              border-radius: 20px;
              cursor: pointer;
              color: #fff;
              font-size: 16px;
              height: 20px;
              line-height: 20px;
              align-items: flex-end;
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }
        .content_itme_tag {
          width: 100%;
          display: flex;
          // margin-bottom: 20px;
          border-bottom: 0.125rem solid #eeeeee;
          span {
            cursor: default;
            margin-right: 10px;
            background: #e6edff;
            color: #0d39a8;
            font-size: 14px;
            padding: 6px 15px;
            border-radius: 20px;
          }
        }
      }
      .iconBox {
        width: fit-content;
        margin: 0 auto;
        text-align: center;
        .svg-icon {
          font-size: 80px;
        }
        .nodata {
          text-align: center;
        }
      }
    }
    .main_Pagination {
      width: 100%;
      padding: 15px 360px 35px 360px;
      position: relative;
      .result{
        position: absolute;
        left: 50px;
        top: 25px;
        color: #7A7B80;
      }
    }
  }
}
</style>